<template lang="pug">
  v-container.pa-0(fluid, v-if='local.dialogFormVisible')
    v-dialog(v-model='local.dialogFormVisible', width='1500')
      v-card
        v-card-title.headline.grey.lighten-2
          .title_setting Изменение данных
        v-card-text.mt-8
          v-form.form-block
            v-row.ml-10(align='center')
              v-subheader.ml-12.pb-6 Имя
                sup.text-error *
              v-text-field.mr-4(
                height='40',
                outlined,
                dense,
                type='text',
                value='name',
                placeholder='Имя',
                validate-on-blur,
                :rules='rules.name',
                v-model='selfInfo.name'
              )
            v-row.ml-4(align='center')
              v-subheader.ml-3.pb-6 Почта (email)
                sup.text-error *
              v-text-field.mr-4(
                height='40',
                outlined,
                dense,
                value='email',
                type='email',
                placeholder='Почта',
                validate-on-blur,
                :rules='rules.email',
                v-model='selfInfo.email'
              )
            v-row.ml-8(align='center')
              v-subheader.ml-7.pb-6 Телефон
              v-text-field.mr-4(
                height='40',
                outlined,
                dense,
                value='phone'
                type='tel',
                placeholder='Телефон',
                v-model='selfInfo.phone'
              )
            v-row.ml-8(align='center')
              v-subheader.ml-8 Пароль
              v-btn(
                height='40',
                outlined,
                text,
                large,
                color='#616161',
                elevation='0',
                @click='newPassword = ""; oldPassword = ""; dialogEditPasswordVisible = true'
              ) Изменить
          v-card-actions
            v-spacer
            v-btn.mr-2(
              width='90',
              outlined,
              text,
              height='40',
              color='#616161',
              elevation='0',
              @click='local.dialogFormVisible = false'
            ) Отмена
            v-btn(
              width='180',
              height='40',
              large,
              elevation='0',
              color='accent',,
              @click='updateThisUser'
            ) Сохранить изменения
    v-dialog(v-model='dialogEditPasswordVisible', width='1500')
      v-card(elevation='0')
        v-card-title.headline.grey.lighten-2
          .title_setting Изменение пароля
        v-card-text.mt-5
          v-row.mt-8
            v-subheader.ml-10.pb-2 Старый пароль
            v-text-field.mr-8(
              validate-on-blur,
              :rules='rules.password',
              v-model='oldPassword',
              placeholder='Старый пароль',
              height='40',
              dense,
              outlined
            )
          v-row
            v-subheader.ml-12.pb-2 Новый пароль
            v-text-field.mr-8(
              validate-on-blur,
              :rules='rules.password',
              v-model='newPassword',
              placeholder='Новый пароль',
              height='40',
              dense,
              outlined
            )
          v-card-actions
            v-spacer
            v-btn.mr-2(
              width='90',
              outlined,
              text,
              height='40',
              color='#616161',
              elevation='0',
              @click='dialogEditPasswordVisible = false'
            ) Отмена
            v-btn(
              width='180',
              height='40',
              large,
              elevation='0',
              color='accent',,
              @click='editPassword'
            ) Сохранить изменения
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {USER_INFO, CHANGE_PASSWORD, UPDATE_USER} from '@/store/const/user'
import loading from '@/mixins/loading_mixin'

export default {
  mixins: [loading],
  props: {
    dialogFormVisible: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      local: {
        dialogFormVisible: false,
      },
      dialogEditPasswordVisible: false,
      selfInfo: {
        name: '',
        email: '',
        phone: '',
        id: '',
        login: '',
        role: '',
      },
      oldPassword: '',
      newPassword: '',
      rules: {
        name: [v => !!v || 'Пожалуйста, введите имя'],
        email: [
          v => !!v || 'Пожалуйста, введите email',
          v => /.+@.+/.test(v) || 'Введите корректный email'
        ],
        password: [
          v => !!v || 'Пожалуйста, введите пароль',
          v => v.length >= 4 || 'Пароль должен содержать больше 4 символов'
        ],
      },
    };
  },
  computed: {
    ...mapGetters('User', ['getUserInfo']),
    ...mapGetters(['loading']),
  },
  watch: {
    dialogFormVisible() {
      this.local.dialogFormVisible = this.dialogFormVisible;
    },
    'local.dialogFormVisible': {
      handler: function () {
        this.$emit('update:dialogFormVisible', this.local.dialogFormVisible);
      },
      deep: true,
    },
  },
  mounted() {
    this.getSelfInfo();
  },
  methods: {
    ...mapActions('User', [
      CHANGE_PASSWORD,
      USER_INFO,
      UPDATE_USER
    ]),
    async getSelfInfo() {
      await this.USER_INFO();
      if (this.isError(USER_INFO)) {
        this.$notify({
          type: 'warning',
          title: 'Ошибка',
          text: 'Не удалось получить данные',
        });
        return
      }
      this.selfInfo = this.getUserInfo
    },
    async editPassword() {
      let data = {
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
      }
      await this.CHANGE_PASSWORD(data)
      if (this.isError(CHANGE_PASSWORD)) {
        this.$notify({
          type: 'error',
          text: 'Ошибка',
        })
        return
      }
      this.dialogEditPasswordVisible = false
      this.$notify({
        type: 'success',
        text: 'Пароль успешно изменен',
      })
    },
    async updateThisUser() {
      await this.UPDATE_USER(this.selfInfo)
      if (this.isError(USER_INFO)) {
        this.$notify({
          type: 'error',
          text: 'Не удалось обновить данные',
        })
        return
      }
      this.dialogFormVisible = false
      this.$notify({
        type: 'success',
        text: 'Данные пользателя успешно обновлены',
      })
    },
  },
};
</script>

<style lang="scss">
.form-block {
  max-height: 736px;
  padding: 15px;
  overflow-y: auto;
}

.title_setting {
  font-size: 30px;
}
</style>
