<template lang="pug">
  div
    v-dialog(v-model='toggleModal')
      v-card
        v-card-title.headline.grey.lighten-2
          div Запрос на пополнение баланса
        v-card-text.mt-5
          v-text-field(
            type='number',
            v-model='msg',
            placeholder='100',
            @keyup.enter='sendMessage',
            dense,
            outlined,
            hide-details
          )
          .mt-5(style='text-align: right')
            v-btn(elevation='0', color='accent', :disabled='msg == 0', @click='sendMessage') Запросить
    v-app-bar.pt-1(height='45px' elevation="0" color='#FFFFFF' :class="{'grey darken-3': interface_name === 'agency_list'}")
      v-app-bar-nav-icon(v-if='!drawer && interface_name !== "agency_list"' @click='toggleDrawer')
      img.logo(src='@/assets/img/radar-ooh.svg' v-if='interface_name === "agency_list"')
      v-toolbar-title.page-title {{getPageTitle}}
      v-spacer
      .d-flex.align-center.justify-end
        template(v-if='interface_name === "account"')
          span.fz-14.pr-2 {{formatNumber(getBalance)}} руб
          img.pr-10(src='@/assets/icons/campaign-money.svg')
        v-menu(v-if='getUserInfo.name'
          open-on-hover
          bottom
          :open-delay='100'
          :close-delay='300'
          :nudge-bottom='40'
        )
          template(v-slot:activator="{ on }")
            .d-flex.align-center.pointer.mr-3(v-on='on')
              .userblockContatiner(v-if='getUserInfo.name' :class="{'pb-2': interface_name === 'agency_list'}")
                .campaign-name(:class="{'white--text': interface_name === 'agency_list'}") {{ getUserInfo.name ? getUserInfo.name.split(' ')[0] : '--'}}
                  span(v-if='!["", "dmpcabinet", "agency_list"].includes(interface_name)') ({{getInfo.ownername}})
                .campaign-desc(:class="{'white--text': interface_name === 'agency_list'}") {{ getUserInfo.email }}
              .d-flex.align-center(:class="{'pb-2': interface_name === 'agency_list'}")
                v-btn(
                  x-small
                  color="#4A55B0"
                  fab
                  dark
                )
                  img(src='@/assets/icons/profile-logo.svg')
          v-list.pointer
            v-list-item(v-if='interface_name === "account"')
              div(@click='clipboardCopy(getUserInfo.id)')
                | Мой ID: {{ getUserInfo.id }}
            v-list-item(
              @click.native='toggleModal = true',
              v-if='interface_name !== "root" && interface_name !== "agency_list" && ["DmpCabinet", "SspCabinet", "ReportCabinet"].indexOf(getUserInfo.role) === -1'
            )
              v-icon(left) mdi-wallet
              | Пополнить баланс
              span(style='font-weight: 500', v-if='getInfo.balance') (  {{ getInfo.balance.toFixed(2) }}  руб.)
            v-list-item(
              @click.native='dialogFormVisible = true',
              v-if='["DmpCabinet", "SspCabinet", "ReportCabinet"].indexOf(getUserInfo.role) === -1'
            )
              v-icon(left) mdi-account-edit
              | Настройки
            v-list-item(
              @click.native='setAgencySession()',
              v-if='interface_name === "account"',
              divided=''
            )
              v-icon(left) mdi-logout
              | Вернуться в агенство
            v-list-item(v-if='interface_name !== "auth"', @click.native='$router.push("/")')
              v-icon(left) mdi-logout
              | Выбрать кабинет
            v-list-item(@click.native='logout')
              v-icon(left) mdi-logout
              | Выход
        .loading(v-else)
    SelfInfo(:dialogFormVisible.sync='dialogFormVisible')
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import SelfInfo from '@/components/SelfInfo';
import {TOGGLE_DRAWER} from '@/store/const/request'
import {LOGOUT} from '@/store/const/auth';
import {GET_ACCOUNT_DATA, SEND_MESSAGE} from '@/store/const/agency';
import {formatRquestStatsData} from '@/mixins/date_mixin';

export default {
  components: {
    SelfInfo
  },
	mixins: [formatRquestStatsData],
  props: {
    interface_name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      routes: {
        account: {
          name: 'Рекламные кампании',
        },
        'account-usersmanagment': {
          name: 'Пользователи',
        },
        'account-files': {
          name: 'Креативы',
        },
        'account-creatives': {
          name: 'Креативы',
        },
        'account-BlackWhiteList': {
          name: 'Инвентарь',
        },
        'account-remarketing': {
          name: 'Ретаргетинг',
        },
        'account-reports': {
          name: 'Отчеты',
        },
        'account-campaign-id': {
          name: 'Рекламная кампания',
        },
        'account-bannerstatus': {
          name: 'Модерация креативов',
        },
        'agency-reports': {
          name: 'Отчеты',
        },
        'agency-advertisers': {
          name: 'Рекламодатели',
        },
        agency: {
          name: 'Рекламодатели',
        },
        'agency-usersmanagment': {
          name: 'Пользователи',
        },
        'agency-table': {
          name: 'Сводная таблица',
        },
        'agency-fin': {
          name: 'Финансы',
        },
      },
      toggleModal: false,
      msg: '',
      dialogFormVisible: false,
    };
  },
  computed: {
		...mapGetters('Auth', ['getCurrentLocation']),
    ...mapGetters('User', ['getUserInfo']),
    ...mapGetters('Root', ['getInfo']),
    ...mapGetters('Agency', ['getAccountData']),
    ...mapGetters('Campaign', ['getCampaign']),
    ...mapGetters(['drawer']),
    getBalance() {
			return this.getAccountData.length ? this.getAccountData.find(item => item.id === this.getCurrentLocation).balance : 0
    },
    getPageTitle() {
      return this.$route.meta.title ? this.$route.meta.title : ''
    }
  },
  methods: {
    ...mapMutations({
      toggleDrawer: TOGGLE_DRAWER
    }),
    ...mapMutations('Auth', [LOGOUT]),
    ...mapActions('Agency', [SEND_MESSAGE, GET_ACCOUNT_DATA]),

    clipboardCopy(id) {
      navigator.clipboard.writeText(id);
      this.$notify({
        type: 'success',
        text: 'Ваш ID скопирован',
      });
    },
    setAgencySession() {
      sessionStorage.setItem('session', sessionStorage.getItem('sessionAgency'));
      sessionStorage.setItem('currentLocation', sessionStorage.getItem('currentLocationAgency'));
      this.$router.push('/agency');
    },
    logout() {
      this.LOGOUT();
      this.$router.push('/login');
    },
    async sendMessage() {
      const sum = +this.msg;
      if (sum === 0 || this.msg === '') {
        this.$notify({
          title: 'Ошибка запроса пополнения',
          text: 'Необходимо указать сумму',
          type: 'error',
        });
        return;
      }

      const data = {
        message: `Просьба пополнить баланс на ${sum.toString()}`,
      };
      await this.SEND_MESSAGE(data)
      if (this.isError(SEND_MESSAGE)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'При отправке запроса возникла ошибка!',
        });
        return
      }
      this.$notify({
        type: 'success',
        title: 'Успешно',
        text: `Запрос на сумму ${sum} успешно отправлен`,
      });
      this.toggleModal = false;
      this.msg = '';
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}

.userblockContatiner {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  text-align: right;
  font-size: 12px;
}

.logo {
  display: inline-block;
  margin: 0 auto 5px 10px;
  height: 18px;
  padding: 0;
}

.page-title {
  font-size: 36px;
  font-weight: 700;
  padding-left: 12px;
  color: #000000;
}

.campaign-name {
  font-weight: 700;
  margin: 3px 0 -2px;
  font-size: 12px;
  color: #131313;
}

.campaign-desc {
  font-weight: 400;
  font-size: 12px;
  color: #131313;
}
</style>
